import React from 'react';
import HubspotForm from 'react-hubspot-form';
import { Container } from './styles/EmailCaptureForm.styled';
import setValuesFromParams from './utils/setValuesFromParams';

const EmailCaptureForm = ({ formId }) => (
  <Container id="hub-form">
    <HubspotForm
      formId={formId}
      onFormReady={setValuesFromParams}
      portalId="5424293"
    />
  </Container>
);

export default EmailCaptureForm;
